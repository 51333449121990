<template>
  <div>
    <ServicesTopBar />
    <Banner title="Privacy Policy" />
    <section class="page_detail_section">
      <div class="container">
        <b-card class="details-page-box">
          <blockquote class="blockquote mb-0">
            <div v-html="contents.content" />
          </blockquote>
        </b-card>
      </div>
    </section>
    <SiteFooter />
  </div>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import Banner from '../components/page/Banner.vue'
import ServicesTopBar from '../components/services/ServicesTopBar.vue'
import SiteFooter from '../components/site/SiteFooter.vue'

export default {
  components: {
    ServicesTopBar, SiteFooter, Banner, BCard,
  },
  data() {
    return {

    }
  },
  computed: {
    contents() {
      return this.$store.state.page_content.privacy_contents
    },
  },
  mounted() {
    this.getContent()
  },
  methods: {
    getImage(image) {
      return `background: url(${image})`
    },
    getContent() {
      return this.$store.dispatch('page_content/getAllPrivacyContent', 'privacy-policy')
    },
  },
}
</script>

<style scoped>

</style>
